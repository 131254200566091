// SilverPackage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import '../styles/PackageDetails.css';

function SilverPackage() {
  return (
    <div className="package-details silver-package">
      <Helmet>
        <title>Silver Package - Wide Explorer</title>
        <meta name="description" content="Explore the Silver Package from Wide Explorer - offering essential visa support for your travel needs." />
        <meta name="keywords" content="Silver Visa Package, Basic Visa Support, Wide Explorer" />
      </Helmet>

      <Link to="/packages" className="back-button">Back to Packages</Link>

      <div className="package-content">
        <FontAwesomeIcon icon={faMedal} className="package-icon" />
        <h2>Silver Package</h2>
        <p>
          The Silver Package provides essential support for your visa application. Ideal for those needing basic assistance to navigate the visa process.
        </p>
        
        <div className="features">
          <h3>Features</h3>
          <ul>
            <li>Step-by-step visa guidance</li>
            <li>Email support</li>
            <li>Basic document preparation</li>
          </ul>
        </div>

        {/* <div className="pricing">
          <h3>Price</h3>
          <p>Starting at</p>
        </div> */}
        {/* Coming soon */}
        {/* <button className="cta-button">Start Application</button> */}
      </div>
    </div>
  );
}

export default SilverPackage;
