// Footer.js
import React from 'react';
import '../styles/Footer.css'; // Import the CSS for styling

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h4>Contact Information</h4>
        <p>Phone: +1 (917) 792-9973</p>
        <p>Email: info@wideexplorer.com</p>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Wide Explorer, a business of SedegoTech. All rights reserved. Developed by SedegoTech</p>
      </div>
    </footer>
  );
}

export default Footer;
