// Packages.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faStar, faGem } from '@fortawesome/free-solid-svg-icons';
import '../styles/Packages.css';

function Packages() {
  return (
    <div className="packages-page">
      <Helmet>
        <title>Packages - Wide Explorer</title>
      </Helmet>
      <h2>Explore Our Visa Packages</h2>
      <Link to="/packages/silver">
        <div className="package-detail-card">
          <h3>
            <FontAwesomeIcon icon={faMedal} className="package-icon silver-icon" /> Silver Package
          </h3>
          <p>
            The Silver Package provides basic support for your visa application. This is ideal for those who need essential guidance to start their application.
          </p>
        </div>
      </Link>
      <Link to="/packages/gold">
        <div className="package-detail-card">
          <h3>
            <FontAwesomeIcon icon={faStar} className="package-icon gold-icon" /> Gold Package
          </h3>
          <p>
            The Gold Package offers enhanced support with priority assistance to help ensure a smooth application process.
          </p>
        </div>
      </Link>
      
      <Link to="/packages/diamond">
      <div className="package-detail-card">
        <h3>
          <FontAwesomeIcon icon={faGem} className="package-icon diamond-icon" /> Diamond Package
        </h3>
        <p>
          The Diamond Package includes full assistance with personalized guidance and expedited processing to ensure you have the best chance of success.
        </p>
      </div>
      </Link>
    </div>
  );
}

export default Packages;
