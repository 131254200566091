// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faStar, faGem } from '@fortawesome/free-solid-svg-icons';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-container">
      <Helmet>
        <title>Home - Wide Explorer</title>
      </Helmet>
      
      {/* Hero Section */}
      <section className="hero-section">
        <video className="hero-video" autoPlay loop muted>
          <source src="/v.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>Welcome to Wide Explorer</h1>
          <p>Your trusted travel partner for visa applications and travel packages.</p>
          <Link to="/packages">
            <button className="cta-button">Start Your Application</button>
          </Link>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about-us">
        <h2>About Wide Explorer</h2>
        <p>
          With years of experience, we provide expert guidance to help you secure your visa efficiently and confidently.
        </p>
      </section>

      {/* Packages Preview Section */}
      <section className="packages-preview">
        <h2>Our Visa Packages</h2>
        <div className="package-cards">
          <div className="package-card">
            <FontAwesomeIcon icon={faMedal} className="package-icon silver-icon" />
            <h3>Silver Package</h3>
            <p>Basic support for your visa application.</p>
            <Link to="/packages/silver" className="learn-more-button">Learn More</Link>
          </div>
          <div className="package-card">
            <FontAwesomeIcon icon={faStar} className="package-icon gold-icon" />
            <h3>Gold Package</h3>
            <p>Priority support for your application.</p>
            <Link to="/packages/gold" className="learn-more-button">Learn More</Link>
          </div>
          <div className="package-card">
            <FontAwesomeIcon icon={faGem} className="package-icon diamond-icon" />
            <h3>Diamond Package</h3>
            <p>Full assistance and personalized guidance.</p>
            <Link to="/packages/diamond" className="learn-more-button">Learn More</Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
