// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; // Import the CSS for styling

function Navbar() {
  return (
    <header className="navbar-container">
      <nav className="navbar">
        <div className="logo">
          <Link to="/">Wide Explorer</Link>
        </div>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/packages">Packages</Link></li>
          {/* <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/register">Register</Link></li> */}
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
